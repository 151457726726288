import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import "./start_menu.css";

const StartMenu = ({
  apps,
  menu_apps,
  disabled_apps,
  playC,
  playE,
  openApp,
  menu,
  showMenu,
  restart,
  dark,
}) => {
  const activeMenu = "main";
  const dropdownRef = useRef(null);

  const DropdownItem = (props) => {
    return (
      <button
        className={"menu-item " + (dark && "menu-item-dark ")}
        key={props.value.id}
        onClick={() => {
          if (!disabled_apps[props.value.id]) {
            playC();
            openApp(props.value.id);
            showMenu();
          } else {
            playE();
          }
        }}
        style={disabled_apps[props.value.id] ? { cursor: "not-allowed" } : {}}
      >
        <img
          className="menu-icon"
          src={props.value.image}
          alt={`${props.value.id}`}
        />
        <div className="menu-text">{props.children}</div>
      </button>
    );
  };

  const DropdownShutDownItem = (props) => {
    return (
      <button
        className={"menu-item-shut " + (dark && "menu-item-shut-dark ")}
        onClick={() => {
          showMenu();
          restart();
        }}
      >
        <img
          className="menu-icon-shut"
          src={props.value.image}
          alt={`${props.value.id}`}
        />
        <div className="menu-text">{props.children}</div>
      </button>
    );
  };

  const renderMenuItems = () => {
    let menuJsx = [];
    apps.forEach((app, index) => {
      if (menu_apps.includes(app.id)) {
        menuJsx.push(
          <DropdownItem
            key={app.id}
            value={{ image: `${app.icon}`, id: `${app.id}` }}
          >
            {app.title}
          </DropdownItem>
        );
      }
    });
    return menuJsx;
  };

  return (
    <div className={"dropdown " + (dark && "dropdown-dark ")} ref={dropdownRef}>
      <div className="dropdown-sidebar">
        <div className="windows-text">
          <strong className="win-text">Windows</strong>
          <strong className="numb-text">95</strong>
        </div>
      </div>

      <CSSTransition
        in={activeMenu === "main"}
        timeout={500}
        classNames="menu-primary"
        unmountOnExit
      >
        <div className="menu">
          {renderMenuItems()}
          <DropdownShutDownItem
            value={{ image: "./images/icon/shut_down.png", id: "restart" }}
          >
            Restart...
          </DropdownShutDownItem>
        </div>
      </CSSTransition>
    </div>
  );
};

export default StartMenu;
