import React from "react";
import "./projects.css";

const Projects = ({ dark, playC }) => {
  const project_list = [
    {
      name: "Entercoding Frontend",
      date: "Feb 2021",
      link: "https://github.com/whopravinewa/EnterCoding",
      description: [
        "React Web application to take online interview with feature like code editor, video chat, peer to peer collaboration of code.",
      ],
      domains: ["react", "javascript"],
    },
    {
      name: "College Management",
      date: "Nov 2020",
      link: "https://github.com/whopravinewa/college-app-microservice",
      description: [
        "Spring boot application to learn about microservices and different server like eureka server, config server etc.",
      ],
      domains: ["spring", "java", "mysql"],
    },
    {
      name: "Project Management",
      date: "Sept 2020",
      link: "https://github.com/whopravinewa/projectmgmnt",
      description: ["Spring boot application to manage projects of developer."],
      domains: ["spring", "java", "mysql"],
    },
    {
      name: "Recipe Application",
      date: "Sept 2020",
      link: "https://github.com/whopravinewa/Recipe-App",
      description: [
        "Simple Recpie web appplication bulid with Java, Spring, Maven and hibernate (H2 memory database)",
      ],
      domains: ["spring", "java"],
    },
    {
      name: "ToDo Application",
      date: "Aug 2020",
      link: "https://github.com/whopravinewa/todo-application",
      description: [
        "Simple ToDo list web appplication bulid with Java, Jstl and Maven",
      ],
      domains: ["spring", "java"],
    },
    {
      name: "Shop Registrar",
      date: "Jul 2020",
      link: "https://github.com/whopravinewa/shop-registrar",
      description: [
        "Mobile application that replace the physical registrar to store stocks items and sold items build with Flutter.",
      ],
      domains: ["flutter", "dart", "mysql", "android"],
    },
    {
      name: "Entercoding Backend",
      date: "Mar 2020",
      link: "https://github.com/whopravinewa/entercoding-api",
      description: [
        "Backend Server build with nodejs for entercoding application.",
      ],
      domains: ["javascript", "node"],
    },
    {
      name: "FoodHUT",
      date: "Aug 2019",
      link: "https://github.com/whopravinewa/FOODHutFinaal",
      description: [
        "Mobile Application bulit with java where seller and buyer can buy food products.",
      ],
      domains: ["java", "android", "firebase"],
    },
    {
      name: "Shopping Site (No SQL)",
      date: "Jan 2019",
      link: "https://github.com/whopravinewa/shopping-site-nosql",
      description: [
        "Simple Shopping Site web application bulit with react and with backend nodejs where nosql database is used to store data.",
      ],
      domains: ["react", "javascript", "node", "mongodb"],
    },
    {
      name: "Shopping Site (My SQL)",
      date: "Dec 2018",
      link: "https://github.com/whopravinewa/shopping-site-mysql",
      description: [
        "Simple Shopping Site web application bulit with react and with backend nodejs where MySql database is used to store data.",
      ],
      domains: ["react", "javascript", "mysql", "node"],
    },
    {
      name: "Automobile Registration System",
      date: "Aug 2018",
      link: "https://github.com/whopravinewa/ARSystem",
      description: [
        "Desktop application build with Java for registration of automobiles(Car, Bike, Bus etc) which can be use by different Automobile companies to store the user details and vehicle details. It support both staff view(panel) and admin view(panel).",
      ],
      domains: ["java", "mysql"],
    },
  ];

  const tag_colors = {
    javascript: "black",
    firebase: "darkorange",
    android: "darkcyan",
    spring: "darkgreen",
    flutter: "darkturquoise",
    dart: "darkblue",
    react: "purple",
    mysql: "darkviolet",
    java: "darkred",
    node: "darkslategrey",
    mongodb: "darkslateblue",
  };

  return (
    <div>
      <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
        {project_list.map((project, index) => {
          return (
            <a
              key={index}
              href={project.link}
              onClick={() => {
                playC();
              }}
              target="_blank"
              rel="noreferrer"
              style={{
                display: "flex",
                width: "100%",
                paddingLeft: "16px",
                paddingRight: "16px",
                textDecoration: "none",
              }}
            >
              <div
                className={
                  dark ? "project-container-dark " : "project-container "
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "18px",
                      lineHeight: "24px",
                      fontWeight: "500",
                    }}
                  >
                    {project.name}
                  </div>
                  <div
                    style={{
                      fontWeight: "300",
                      fontSize: "12px",
                      lineHeight: "20px",
                    }}
                  >
                    {project.date}
                  </div>
                </div>
                <ul
                  style={{
                    marginTop: "4px",
                    fontWeight: "300",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  {project.description.map((desc, index) => {
                    return (
                      <li
                        key={index}
                        className="list-arrow"
                        style={{ marginTop: "4px" }}
                      >
                        {desc}
                      </li>
                    );
                  })}
                </ul>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    fontSize: "12px",
                    lineHeight: "16px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                  }}
                >
                  {project.domains
                    ? project.domains.map((domain, index) => {
                        let tag_color = tag_colors[domain];
                        return (
                          <span
                            key={index}
                            className="domain-tag"
                            style={{
                              borderColor: `${tag_color}`,
                              color: `${tag_color}`,
                            }}
                          >
                            {domain}
                          </span>
                        );
                      })
                    : null}
                </div>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default Projects;

export const displayProjects = ({ dark, playC }) => {
  return (
    <div className="projects-main">
      <div className={dark ? "project-content-i-dark " : "project-content-i "}>
        <div
          className={dark ? "project-content-ii-dark " : "project-content-ii "}
        >
          <Projects dark={dark} playC={playC}></Projects>
        </div>
      </div>
    </div>
  );
};
